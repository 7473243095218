import { ErrorCode } from 'react-dropzone';
import { NotificationCanal, NotificationPriority, NotificationType } from './notifications';
import { AirtableAccountManager, CRAStatus, MissionType } from './airtable';
import { Upload } from 'graphql-upload';
import { SmartFields } from './ports/esign';
import { DealType } from './domain';

export const AddressCountryCodeEU = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
];

export interface BrowserUploadFile extends Partial<File> {
  url?: string;
  path?: string;
  errors?: { message: string; code: ErrorCode | string }[];
}

export type DataValue = {
  firstname: string;
  lastname: string;
  email: string;
  jobTitle: string;
  linkedIn?: string;
};

export enum ActivityDayType {
  'nbDaysWorked' = 'nbDaysWorked',
  'nbDaysMarkupWorked' = 'nbDaysMarkupWorked',
  'nbDaysMarkup2Worked' = 'nbDaysMarkup2Worked',
  'astreinte' = 'astreinte',
}

export type ActivityDayDetails = {
  id: number;
  day: string;
  date: string;
  isHoliday: boolean;
  isSunday: boolean;
  isSaturday: boolean;
  isWeekEndDay: boolean;
  dayWorked?: number;
  checked: boolean;
  comment?: string;
  astreinte?: number;
};

export type ActivityNbDayDetails = {
  [key: number]: {
    duration: number;
    comment?: string;
    isAstreinte: number;
  };
};

export type DataCSV = {
  data: {
    line: number | string;
    firstname: string;
    lastname: string;
    email: string;
    jobTitle: string;
    linkedIn?: string | undefined;
  };
};

export enum RemoteWork {
  Flexible = 'Flexible',
  Remote = 'Remote',
  OnSite = 'OnSite',
}

export enum Status {
  CDI = 'CDI',
  Freelance = 'Freelance',
  FreeAndCDI = 'FreeAndCDI',
}

export enum BloomerStatus {
  Bloomer = 'Bloomer',
  SuperBloomer = 'SuperBloomer',
}

export enum BackgroundType {
  Job = 'Job',
  Education = 'Education',
}

export class Location {
  id?: string;
  address?: string;
  postalCode?: string;
  city?: string;
  countryCode?: string;
  region?: string;
  longitude?: number;
  latitude?: number;
  range?: number;
}

export class ISkill implements ILabels {
  id!: string;
  label!: string;
  iso!: boolean;
  alternateLabels!: string[];
}

export class BackgroundTalent {
  id!: string;
  label?: string;
  description?: string;
  url?: string;
  type?: BackgroundType;
  name!: string;
}

export class SkillTalent {
  id?: string;
  current?: boolean;
  skill!: ISkill;
  period?: IPeriod;
  level?: number;
  background?: BackgroundTalent;
}

export class JobTitleCategory {
  id!: string;
  label!: string;
}

export class IJobTitle implements ILabels {
  id!: string;
  label!: string;
  iso!: boolean;
  alternateLabels!: string[];
  category?: JobTitleCategory;
}

export class JobTalent {
  id?: string;
  jobTitle?: { id?: string; label: string; iso?: boolean }[];
  period?: IPeriod;
  current?: boolean;
  label?: string;
  description?: string;
  url?: string;
  type?: BackgroundType;
  name?: string;
  talentId?: string;
  skills?: ILabels[];
}

export class SkillTalentLevel {
  id!: string;
  skill!: ISkill;
  level?: number;
}

export class EducationTitleCategory {
  id!: string;
  label!: string;
}

export class EducationTitle {
  id!: string;
  iso!: boolean;
  alternateLabels!: string[];
  label!: string;
  category?: EducationTitleCategory;
}

export class EducationTalent {
  id?: string;
  type?: BackgroundType;
  name!: string;
  label?: string;
  description?: string;
  url?: string;
  current?: boolean;
  talentId?: string;
  period?: IPeriod;
}

export class LanguagesTalent {
  id?: string;
  label!: string;
  level?: number;
}

export interface ITalent {
  id?: string;
  fullname?: string;
  firstname: string;
  lastname: string;
  positionTitle?: { id?: string; jobTitle: IJobTitle; talentId?: string }; // Champs normalisé
  jobTitle: string; // Champs normalisé
  label?: string; // Titre Linkedin
  cv?: string;
  image?: string;
  email?: string;
  secondaryEmails?: string[];
  phone?: string;
  secondaryPhones?: string[];
  url?: string;
  secondaryUrls?: string[];
  summary?: string;
  status?: Status;
  remoteWork?: RemoteWork;
  owner?: string;
  bloomerStatus?: BloomerStatus;
  tjm?: number;
  package?: number;
  packageVariable?: number;
  availabilityDate?: Date;
  location?: Location;
  profiles?: Profile[];
  locationId?: string;
  jobs?: JobTalent[];
  educations?: EducationTalent[];
  languages?: LanguagesTalent[];
  skills?: SkillTalent[];
  skillLevels?: SkillTalentLevel[];
  comment?: string;
  createdAt?: Date;
  updatedAt?: Date;
  origin: ProfileNetwork | string;
}

export interface ITag {
  value: string;
  count?: number;
}

export type Paginate = {
  take: number;
  skip: number;
};

export type Item = {
  id: string;
  minLevel: number;
  label: string;
};

/*export interface ISkill extends ILabels {
  id: string;
}

export interface IJobTitle extends ILabels {
  id: string;
}*/

export type ItemSkills = {
  isToDelete?: boolean;
  id: string;
  skillId?: string;
  level?: number;
  label: string;
  selected?: boolean;
};

export interface ISkillInput {
  id?: string;
  label?: string;
  iso?: boolean;
  alternateLabels?: string[];
  level?: number;
}

export type DirectSkill = {
  id?: string;
  skill: ISkillInput;
  level?: number;
  talentId?: string;
};

export type ItemJobs = {
  id: string;
  level?: number;
  label: string;
  selected?: boolean;
};

export type ItemLanguages = {
  id: string;
  label: string;
  selected?: boolean;
  level?: number;
};

export type Language = {
  id: string;
  label: string;
  level?: number;
  talentId?: string;
};

export interface IPeriodCommon {
  endDate?: string;
}

export interface IPeriod extends IPeriodCommon {
  startDate: string;
  id?: string;
}

export interface IPeriodInput extends IPeriodCommon {
  id?: string;
  startDate?: string;
}

export class JobTitleInput {
  id?: string;
  label?: string;
  iso?: boolean;
  alternateLabels?: string[];
}

export enum ProfileNetwork {
  LinkedIn = 'LinkedIn',
  Github = 'Github',
  TurnoverIT = 'TurnoverIT',
  Twitter = 'Twitter',
  HelloWork = 'HelloWork',
}

export type Profile = {
  id?: string;
  username?: string;
  network?: ProfileNetwork | string;
  url?: string;
  talentId?: string;
};

export enum EvenementType {
  createTalent = 'createTalent',
  updateTalent = 'updateTalent',
  createJobTalent = 'createJobTalent',
  updateJobTalent = 'updateJobTalent',
  deleteJobTalent = 'deleteJobTalent',
  createEducationTalent = 'createEducationTalent',
  updateEducationTalent = 'updateEducationTalent',
  deleteEducationTalent = 'deleteEducationTalent',
  createDirectSkill = 'createDirectSkill',
  updateDirectSkill = 'updateDirectSkill',
  deleteDirectSkill = 'deleteDirectSkill',
  createLang = 'createLang',
  updateLang = 'updateLang',
  deleteLang = 'deleteLang',
  createAttachmentTalent = 'createAttachmentTalent',
  updateAttachmentTalent = 'updateAttachmentTalent',
  deleteAttachmentTalent = 'deleteAttachmentTalent',
  createJobFunnelTalent = 'createJobFunnelTalent',
  moveJobFunnelTalent = 'moveJobFunnelTalent',
  addComment = 'addComment',
}

export type ActivityLog = {
  id: string;
  talent: string;
  type: EvenementType;
  source: string;
  tam: string;
  data: string;
  previousData?: string;
  date: string;
};

export interface ILabels {
  id: string;
  label: string;
  alternateLabels: string[];
  iso: boolean;
}

// ------------ api.bloomers -------------

export interface IMissionQueryInput {
  research?: string;
}

export type BrowserFieldOrFile = BrowserUploadFile | string;
export type ServerFieldOrFile = Upload | string;

export type BloomerFiles = 'credentialID';

// carefull this interfaceis for client side only
export interface BrowserBloomer extends Omit<IBloomer, 'credentialID'> {
  credentialID?: BrowserFieldOrFile;
}

export interface IBloomer {
  isNew?: boolean;
  notification?: INotification[];
  id?: string;
  fullname?: string;
  firstname: string;
  lastname: string;
  email: string;
  secondaryEmail?: string;
  phoneNumber?: string;
  slack?: boolean;
  accountManager?: IAccountManager;
  accountManagerId?: string;
  accountManagerName?: string;
  mission?: IMission[];
  recordId?: string;
  credentialID?: ServerFieldOrFile;
  invitation?: Invitation;
  communicationType: NotificationCanal;
  pipefyContactId?: string;
  hiresweetContactId?: string;
}

export interface IAccountManager extends Omit<AirtableAccountManager, 'bloomers'> {
  bloomers: BrowserBloomer[];
}

// TODO
export interface IMission {
  contracts?: string[];
  enableCRAUpload?: boolean;
  jobId?: string;
  bdcExpirationDate?: string;
  bdc?: string;
  havePreviousMonthActivity?: string[] | undefined;
  id?: number;
  label?: string;
  beginMission: string;
  endMission: string;
  statusBloomer?: string;
  statusClient?: string;
  bloomer: BrowserBloomer;
  activity: IActivity[];
  activityRecordId: string[];
  pricing: IPricing[];
  pricingRecordId: string[];
  clientSociety?: ISociety;
  clientSocietyRecordId?: string;
  clientBillingSociety?: ISociety;
  clientBillingSocietyRecordId?: string;
  bloomerBillingSociety: ISociety[];
  bloomerBillingSocietyRecordId: string[];
  contactOperations?: IContact;
  contactOperationsRecordId?: string;
  billingContacts: IContact[];
  billingContactsRecordId: string[];
  recordId?: string;
  isActive?: boolean;
  externalId?: string;
  bloomerSocietyName: string[];
  clientSocietyName: string[];
  clientBillingSocietyName?: string[];
  bloomerRecordId: string[];
  bloomerPaydateDays?: number;
  clientPaydateDays?: number;
  type: string;
  marginAmount?: number;
  reasearchField?: string;
  bloomerFullName?: string[];
  accountManager?: IAccountManager[];
  CSM?: IAccountManager;
  CSMRecordId: string[];
  contractType?: string;
  createdAt?: string;
  replacement: boolean;
  hoursPerDays: number;
  origin?: 'pipefy' | 'hiresweet';
}

export type SocietyFiles =
  | 'KBIS'
  | 'URSSAFVigilanceCertificate'
  | 'URSSAFVigilanceCertificate'
  | 'RCPVigilanceCertificate'
  | 'RCPVigilanceCertificate';

export interface ISociety {
  addressPart2?: string;
  id?: string;
  name: string;
  status?: string;
  siret: string;
  isClient?: boolean;
  bloomers?: string[];
  billingContact?: IContact;
  mission?: IMission[];
  recordId?: string;
  KBIS?: string;
  URSSAFVigilanceCertificate?: string;
  URSSAFVCExpirationDate?: string;
  URSSAFVCUploadDate?: string;
  RCPVigilanceCertificate?: string;
  IBAN?: string;
  BIC?: string;
  portage?: boolean;
  paydateDays?: number;
  bloomerBillingMission?: IMission[];
  tva?: string;
  addressName?: string;
  addressPart1?: string;
  addressZip?: string;
  addressTown?: string;
  addressCountryCode?: string | null;
  addressCountry?: string;
  externalId?: string;
  pitch?: string;
  description?: string;
  interviewTips?: string;
  internalOrganization?: string;
  phone?: string;
}

export interface IPricing {
  id?: string;
  startDate?: Date;
  purchasePricePerDayWithoutTaxes: number;
  salePricePerDayWithoutTaxes: number;
  marginAmount?: string;
  marginRate?: string;
  taxes: number;
  activity?: IActivity[];
  mission?: IMission[];
  recordId?: string;
  astreinteCoef?: number;
  recordIdMission: string[];
}

export type ActivityFiles = 'urlCraS3' | 'urlInvoicesS3' | 'urlAdditionalInvoicesS3';

// TODO
export interface IActivity {
  jobId?: string;
  id?: string;
  month: string;
  nbDaysWorked: number;
  nbDaysMarkupWorked: number;
  nbDaysMarkup2Worked: number;
  nbDaysDetails: string;
  astreinte?: number;
  bloomer: BrowserBloomer[];
  mission?: IMission;
  validated: boolean;
  signing?: boolean;
  recordId: string;
  signingDocumentId?: string;
  billed: boolean;
  signedCount?: number;
  urlCraS3?: string;
  urlInvoicesS3?: string;
  urlAdditionalInvoicesS3?: string;
  bloomerFullname: string[];
  clientMission: string[];
  bloomerRecordId: string[];
  marginAmount: number[];
  type?: string[];
  signingEmbedBloomerId?: string;
  signingEmbedClientId?: string;
  recordIdMission: string;
  clientSocietyName: string;
  payed: boolean;
  dateUploadInvoice?: string;
  CRAStatus: CRAStatus;
  lastError?: string;
  lastSupplierError?: string;
  externalCustomerInvoiceId?: string;
  externalSupplierInvoiceId?: string;
  hoursPerDays: number;
}

// TODO : move to airtable file with ISociety / IMission to be created as Airtable types
export interface IContact {
  id?: string;
  fullname?: string;
  firstname: string;
  lastname: string;
  phoneNumber?: string;
  email: string;
  nameJob: string;
  isBilling: boolean;
  isOperational?: boolean | null;
  society?: ISociety[];
  mission?: IMission[];
  recordId?: string;
  externalId: string;
  communicationType: NotificationCanal;
}

export type MissionContractType = 'bloomaysContract' | 'devis' | 'clientContract';

export interface IMissionCardDetails {
  label?: string;
  recordId: string;
  beginMission: string;
  endMission: string;
  purchasePricePerDayWithoutTaxes?: number;
  salePricePerDayWithoutTaxes?: number;
  taxes?: number;
  CSM?: string;
  contractType?: MissionContractType;
  replacement: boolean | null;
  isDeal: boolean | null;
  isActive: boolean;
  bloomerPaydateDays?: number;
  clientPaydateDays?: number;
  type: MissionType;
  prices?: IMissionCardPricing[];
  clientBillingSociety?: ISociety;
  clientSociety?: ISociety;
  pricing?: IPricing;
  bloomerBillingSociety?: ISociety[];
  contactOperations?: IContact;
  billingContacts?: IContact;
  bloomer: IBloomer;
  hoursPerDays: number;
  origin?: 'pipefy' | 'hiresweet';
}

export interface IMissionCardPricing {
  startDate?: Date;
  purchasePricePerDayWithoutTaxes: number;
  salePricePerDayWithoutTaxes: number;
  taxes: number;
  recordId?: string;
  astreinteCoef?: number;
}

export type Invitation = {
  recordId: string;
  email: string;
  token: string;
  jobTitle: string;
  clientSocietyName: string;
  purchasePricePerDayWithoutTaxes: number;
  missionStartDate: Date;
  firstname: string;
  lastname: string;
  mission: string[];
  status: string;
};

export interface IBilling {
  recordId?: string;
  externalId?: string;
  Type?: string;
  BillingDate?: string;
  TrialPeriodEndDate?: string;
  Customer?: string[];
  CustomerName?: string;
  SalesPriceWithoutTaxes?: number;
  BillingContact?: string[];
  BillingContactFullname?: string;
  Billed?: boolean | null;
  KAM?: string[];
  PaydateDays?: number;
  Description: string;
  nextStep?: string;
}

export interface ISorting {
  field: string;
  direction: string;
}

export interface IPagination {
  limit: number;
  offset: number;
}

export interface Recommendations {
  name: string;
  comment: string;
}

export interface IEducations {
  title: string;
  degree: string;
  url: string;
  description: string;
  fieldOfStudy: string;
  beginDate: Date;
  endDate: Date;
}

export interface IExperiences {
  title: string;
  description: string;
  companyName: string;
  url: string;
  beginDate: Date;
  endDate: Date;
}

export interface HardSkills {
  name: string;
  version?: number;
  beginDate?: string;
}

export interface IJobFunnel {
  id: string;
  title: string;
  url: string;
  clientName: string;
}

export interface IMetadata {
  createdAt?: Date | string;
  updatedAt?: Date | string;
  owner?: string | null;
  alreadyBloomer?: boolean;
  superBloomer?: boolean;
}

export interface IPositions {
  title?: string;
  url?: string;
  description?: string;
  companyName?: string;
  date1?: string;
  date2?: string;
  roles?: {
    title?: string;
    description?: string;
    date1?: string;
    date2?: string;
  }[];
}

export interface ILinkedInProfile {
  contact: { type: string; values: string[]; links: string[] }[];
  profile: {
    name?: string;
    headline?: string;
    location?: string;
    summary?: string;
    connections?: string;
    followers?: string;
    positions?: IPositions[] | [];
    imageurl?: string;
    languages: { name: string }[];
  };
  languages: { name: string }[];
  positions?: IPositions[] | [];
  skills?: { title: string }[];
  educations?: IPositions[];
  recommendations?: { received: { text: string; name: string }[] };
}

export interface ILinkedInUrl {
  linkedInUrl: string;
  linkedInCredential: ILinkedInCredential;
}

export interface ILinkedInCredential {
  cookies?: string;
  email?: string;
  password?: string;
}

export enum FinanceType {
  pay = 'pay',
  rent = 'rent',
  stack = 'stack',
  CDI = 'CDI',
  RPO = 'RPO',
  freelance = 'freelance',
  IT = 'IT',
  other = 'other',
  People = 'People',
}

export enum BillingType {
  Recruitement = 'Recruitement',
  Audit = 'Audit',
}

export type WinType =
  | 'Freelance Tech & Digital'
  | 'Freelance RPO'
  | 'Freelance Bloomays People'
  | 'CDI Tech & Digital'
  | 'CDI Bloomays People';

export interface INotification {
  channel?: string;
  userGroup?: string;
  recordId?: string;
  title?: string;
  description?: string;
  priority: NotificationPriority;
  read: boolean;
  bloomer?: string[];
  society?: string[];
  activity?: string[];
  mission?: string[];
  canal: NotificationCanal;
  creationDate: string;
  contact: string | null;
  type: NotificationType;
  deleted: boolean;
  canDelete: boolean;
  sent: boolean;
  sentDate?: Date;
  bloomerRecordId?: string;
  missionRecordId?: string;
  link?: string;
}

export interface ILightSlackNotification
  extends Omit<
    INotification,
    'title' | 'priority' | 'read' | 'deleted' | 'creationDate' | 'sent' | 'type' | 'canDelete' | 'contact'
  > {
  userGroup: string;
  channel: string;
}

export interface NotificationJob {
  type: NotificationType;
}

export interface Preferences {
  communicationType: string;
}

export interface MissionFilter {
  recordId?: string;
  month?: string | Date;
  isMissingCRA?: boolean;
}

export interface PipefyJob {
  id: string;
  title: string;
  url: string;
  clientName: string;
}

export enum TalentProcessStep {
  Prequalification = 'Prequalification',
  Qualification = 'Qualification',
  Selected = 'Selected',
  ReferenceCheck = 'ReferenceCheck',
  InterviewPreparation = 'InterviewPreparation',
  InterviewDebriefing = 'InterviewDebriefing',
  ToConfirm = 'ToConfirm',
  Validated = 'Validated',
  NotValidated = 'NotValidated',
  Archive = 'Archive',
  TBC = 'TBC',
}

export interface TalentProcess {
  phase: string;
  lastname: string;
  firstname: string;
  jobTitle: string;
  location?: string;
  availableDate?: string;
  salesPriceWithoutTaxes?: number;
  pluses?: string;
  emailResponseAddres?: string;
  emailMessagingAddress?: string;
  profilPictureUrl?: string;
  linkedInUrl?: string;
  referenceAccepted?: string;
  experiences?: string;
  CV?: string;
  noGoReason?: string;
  noGoExplanation?: string;
}

export interface JobProcess {
  id: string;
  type: DealType;
  title: string;
  duration: number;
  localization: string;
  startDate: string;
  salesPriceWithoutTaxes: number;
  description: string;
  scorecards: string[];
  nbProfilLKContacted?: number;
  nbProfilPhoneContacted?: number;
  nbProfilEmailContacted?: number;
  responsePerPercentage?: number;
  nbQualifProfil?: number;
  talentsProcess?: TalentProcess[];
}

export interface ContactsInput {
  email: string;
  phoneNumber: string;
}

export enum TemplateContract {
  // sous-traitance
  SST = 'SST',
  // prestation de service
  PDS = 'PDS',
}

export enum CardType {
  candi = 'candi',
  legal = 'legal',
}
export interface WebhookPipefyCardPayload {
  data: {
    action: 'card.create' | 'card.field_update' | 'card.delete';
    card: {
      id: number;
      pipe_id: string;
    };
  };
}

export interface NPSScoring {
  recordId?: string;
  fullName: string;
  email: string;
  created: Date;
  score: number;
  comment?: string;
  collectDate: string;
  source: string;
  activityId: string;
}

export type BasicState = {
  firstname?: string;
  lastname?: string;
  label?: string;
  email?: string;
  url?: string;
  positionTitle?: { id?: string; jobTitle: IJobTitle; talentId?: string };
  secondaryEmails?: string[];
  phone?: string;
  secondaryPhones?: string[];
  remoteWork?: RemoteWork;
  status?: Status;
  tjm?: number;
  package?: number;
  profiles?: Profile[];
  location?: Location;
};

export interface ILocationCommon {
  address?: string;
  postalCode?: string;
  city?: string;
  countryCode?: string;
  region?: string;
  longitude?: number;
  latitude?: number;
  range?: number;
}

export interface ILocation extends ILocationCommon {
  id: string;
}

export interface ILocationInput extends ILocationCommon {
  id?: string;
  talentId?: string;
}

export interface IProfileCommon {
  username?: string;
  network?: ProfileNetwork | string;
  url?: string;
}

export interface IProfile extends IProfileCommon {
  id: string;
  talentId?: string;
}

export interface IProfileInput extends IProfileCommon {
  id?: string;
  talentId?: string;
}

export interface IBasics {
  name?: string;
  firstname: string;
  lastname: string;
  jobTitle: string;
  positionTitle?: { id?: string; talentId?: string; jobTitle: JobTitleInput };
  label?: string;
  image?: string;
  email?: string;
  secondaryEmails?: string[];
  phone?: string;
  secondaryPhones?: string[];
  url?: string;
  secondaryUrls?: string[];
  summary?: string;
  status?: Status;
  remoteWork?: RemoteWork;
  owner?: string;
  bloomerStatus?: string;
  tjm?: number;
  package?: number;
  packageVariable?: number;
  availabilityDate?: string;
  availabilityDelay?: number;
  location?: ILocationInput;
  profiles?: IProfileInput[];
  cv?: string;
  origin?: ProfileNetwork | string;
}

export interface IWorkInput {
  id?: string;
  periodId?: string;
  name?: string;
  label?: string;
  position?: string[];
  url?: string;
  summary?: string;
  startDate?: Date;
  endDate?: Date;
  highlights?: string[];
  skills?: ISkillInput[];
}

export interface IJobInput {
  id?: string;
  current?: boolean;
  period?: IPeriodInput;
  label?: string;
  description?: string;
  url?: string;
  type?: BackgroundType;
  jobTitle?: JobTitleInput[];
  name: string;
  talentId?: string;
  skills?: ISkillInput[];
}

export interface ILanguageCommon {
  label: string;
  level?: number;
  talentId: string;
}

export interface ILanguage extends ILanguageCommon {
  id: string;
}

export interface ILanguageInput extends ILanguageCommon {
  id?: string;
  isToDelete?: boolean;
}

export interface ILanguageBasicsInput extends Omit<ILanguageInput, 'talentId'> {
  talentId?: string;
}

export interface IEducationBasics {
  id?: string;
  periodId?: string;
  institution?: string;
  url?: string;
  area: string;
  studyType?: string;
  courses?: string[];
  score?: string;
  beginDate?: Date;
  endDate?: Date;
}
export interface ITalentInput {
  id?: string;
  legacyObjectID?: string;
  basics?: IBasics;
  work?: IWorkInput[];
  education?: IEducationBasics[];
  skills?: ISkillInput[];
  languages?: ILanguageBasicsInput[];
  comment?: string;
}

export interface ITalentBasicInput {
  id?: string;
  legacyObjectID?: string;
  basics?: IBasics;
  comment?: string;
}

export type ActivityFileFields = 'urlCraS3' | 'urlInvoicesS3' | 'urlAdditionalInvoicesS3';

export type AmendmentFromTemplateResult = {
  signNowDocId: string;
  signerId: string;
  smartFields: SmartFields;
  callbackUrl: string;
};
